import { createAction, props } from '@ngrx/store';
import { HashtagInfo } from '../models/hashtag-info.model';

export type HashtagInfoError = any;

export const fetchHashtagInfoSuccess = createAction(
    '[Hashtag Info API] Fetch Hashtag Info Success',
    props<{ hashtag: HashtagInfo }>(),
);

export const fetchHashtagInfoError = createAction(
    '[Hashtag Info API] Fetch Hashtag Info Error',
    props<{ hashtagId: number; error: HashtagInfoError }>(),
);

export const followHashtagSuccess = createAction(
    '[Hashtag Info] Follow Hashtag Success',
    props<{ hashtagId: number; follow: boolean; showTip?: boolean }>(),
);

export const followHashtagError = createAction(
    '[Hashtag Info] Follow Hashtag Error',
    props<{ hashtagId: number; follow: boolean; error: HashtagInfoError }>(),
);
